<template>
  <div class="user-nav-items">
    <span
      class="feather-icon-wrapper"
      @click="openNotifications"
    >
      <b-link>
        <span class="badge badge-up badge-pill badge-primary">{{ 0 }}</span>
        <img
          :src="require('@/assets/images/app-icons/notification.png')"
          width="21"
        >
      </b-link>
    </span>
    <span class="feather-icon-wrapper mobile-static-position">
      <b-nav-item-dropdown
        class="dropdown-cart mr-25 d-inline-block"
        menu-class="dropdown-menu-media"
        right
      >
        <template #button-content>
          <span class="badge badge-up badge-pill badge-primary">{{ chatArray.length }}</span>
          <img
            :src="require('@/assets/images/app-icons/chat.png')"
            width="21"
          >
        </template>

        <!-- Header -->
        <li class="dropdown-menu-header">
          <div class="dropdown-header d-flex pb-50 justify-content-between">
            <h4 class="notification-title mb-0 mr-auto">
              {{ $t('Chats') }}
            </h4>
          </div>
        </li>

        <!-- Cart Items Service -->
        <li
          v-if="chatArray.length > 0"
          class="scrollable-container media-list scroll-area ps-container scrollable-container media-list scroll-area ps ps--active-y auto-flow-scroll"
          tagname="li"
        >
          <div
            v-for="(item, index) of chatArray"
            :key="index"
            class="chat-nav-section-wrapper border-bottom p-1"
          >
            <b-img
              class="chat-icon-dropdown"
              :src="$helpers.getIcons('chat')"
              @click="setChatInfo(item)"
            />
            <p
              v-if="item.store_name"
              class="mb-0"
            >{{ $t('From:') }} {{ item.store_name }}</p>
            <p
              v-if="item.provider_name"
              class="mb-0"
            >{{ $t('From:') }} {{ item.provider_name }}</p>
            <p class="mb-0">
              {{ item.admin_service == 'SERVICE' ? $t('Service Id:') : $t('Order Number:') }}
              <b-link
                :to="{
                  path: item.admin_service == 'SERVICE' ? '/user/service/request/' + item.request_id : '/user/store/order/' + item.request_id,
                }"
              >
                {{ item.order_number }}
              </b-link>
            </p>
            <div class="d-flex justify-content-between">
              <p class="mb-0">{{ $t('Payment Mode:') }} {{ item.payment_mode }}</p>
              <p
                v-if="item.order_type"
                class="mb-0"
              >{{ $t('Type:') }} {{ item.order_type }}</p>
            </div>
          </div>
        </li>
        <div
          v-else
          class="p-2 text-center"
        >{{ $t('No Chats Available') }}</div>
      </b-nav-item-dropdown>
    </span>
    <b-modal
      id="chat-user-modal-popup-navbar"
      v-model="chatWrapper.showChatMessage"
      :no-close-on-backdrop="true"
      hide-footer
      @hidden="chatWrapper.showChatMessage = false"
    >
      <chat
        :chat-type="'ORDER'"
        :data-id="chatWrapper.orderIdInfo"
        :user-name="chatWrapper.userChatName"
        :customer-id="chatWrapper.userCustomerId"
        :store-provider-id="chatWrapper.storeProviderId"
        :provider-name="chatWrapper.storeChatName"
      />
    </b-modal>
  </div>
</template>

<script>
import { BLink, BNavItemDropdown, BImg, BModal } from 'bootstrap-vue'
import eventBus from '@/libs/event-bus'
import { showErrorNotification } from '@/@core/comp-functions/ui/app'
import Chat from '../../shared/chat/Chat.vue'
import { useUserUi } from '../useUser'

export default {
  components: {
    BLink,
    BModal,
    BNavItemDropdown,
    BImg,
    Chat,
  },
  data() {
    return {
      chatWrapper: {
        showChatMessage: false,
        orderIdInfo: '',
        userChatName: '',
        userCustomerId: '',
        storeProviderId: '',
        storeChatName: '',
      },
      chatNotifications: [],
      chatServiceNotifications: [],
      perfectScrollbarSettings: {
        maxScrollbarLength: 60,
        wheelPropagation: false,
      },
    }
  },
  computed: {
    chatArray() {
      return [...this.chatNotifications, ...this.chatServiceNotifications]
    },
  },
  mounted() {
    this.servicesChat()
    this.ordersChat()
  },
  methods: {
    openNotifications() {
      eventBus.$emit('show-users-notifications')
    },
    setChatInfo(item) {
      this.chatWrapper.orderIdInfo = item.request_id
      this.chatWrapper.userChatName = item.customer_name
      this.chatWrapper.userCustomerId = item.user_id
      this.chatWrapper.storeProviderId = item.store_id
      this.chatWrapper.storeChatName = item.store_name
      this.chatWrapper.showChatMessage = true
    },
    ordersChat() {
      const { ordersChat } = useUserUi()

      ordersChat()
        .then(({ data }) => {
          if (data.statusCode === '200') {
            this.chatNotifications = data.responseData
          }
        })
        .catch(error => {
          showErrorNotification(this, error)
        })
    },
    servicesChat() {
      const { servicesChat } = useUserUi()

      servicesChat()
        .then(({ data }) => {
          if (data.statusCode === '200') {
            this.chatServiceNotifications = data.responseData
          }
        })
        .catch(error => {
          showErrorNotification(this, error)
        })
    },
  },
}
</script>

<style scoped>
.user-nav-items .feather-icon-wrapper {
  margin: 0px 10px;
  position: relative;
}

.user-nav-items img.chat-icon-dropdown {
  position: absolute;
  width: 25px;
  right: 15px;
  cursor: pointer;
}

@media only screen and (max-width: 767px) {
  .user-nav-items .feather-icon-wrapper li {
    float: unset;
  }
}
</style>
