/* eslint-disable global-require */
/* eslint-disable eol-last */
export default [
  {
    title: 'Dashboard',
    route: 'provider-requests',
    image: require('@/assets/images/app-icons/Dashboard.png'),
  },
  {
    title: 'Analytics',
    route: 'provider-analytics',
    image: require('@/assets/images/app-icons/business-report.png'),
  },
  {
    title: 'Offers',
    route: 'provider-promocodes',
    image: require('@/assets/images/app-icons/coupon.png'),
  },
  {
    title: 'History',
    image: require('@/assets/images/app-images/History.png'),
    children: [
      {
        title: 'Order',
        route: 'provider-order',
        image: require('@/assets/images/app-icons/sub-menu/order.png'),
      },
      {
        title: 'Services',
        route: 'provider-services',
        image: require('@/assets/images/app-icons/sub-menu/Service.png'),
      },
    ],
  },
  {
    title: 'My Documents',
    route: 'provider-documents',
    image: require('@/assets/images/app-images/My Documents.png'),
  },
  {
    title: 'My Agents',
    route: 'provider-agents',
    image: require('@/assets/images/app-icons/agent.png'),
  },
  {
    title: 'Bookings',
    route: 'provider-bookings',
    image: require('@/assets/images/app-icons/calendar.png'),
  },
  {
    title: 'HRM',
    image: require('@/assets/images/app-images/icons8-hr-48.png'),
    children: [
      {
        title: 'Departments',
        route: 'provider-hrm-departments',
        image: require('@/assets/images/app-images/department.png'),
      },
      {
        title: 'Designation',
        route: 'provider-hrm-designation',
        image: require('@/assets/images/app-images/designation.png'),
      },
      {
        title: 'Employees',
        route: 'provider-hrm-employees',
        image: require('@/assets/images/app-images/employee.png'),
      },
      {
        title: 'Attendance',
        route: 'provider-hrm-attendance',
        image: require('@/assets/images/app-images/attendance.png'),
      },
      {
        title: 'Holidays',
        route: 'provider-hrm-holidays',
        image: require('@/assets/images/app-images/holidays.png'),
      },
      {
        title: 'Shift',
        route: 'provider-hrm-shift',
        image: require('@/assets/images/app-images/office_shift.png'),
      },
      {
        title: 'Leaves',
        route: 'provider-hrm-leaves',
        image: require('@/assets/images/app-images/leave_request.png'),
      },
      {
        title: 'Leave types',
        route: 'provider-leave_type',
        image: require('@/assets/images/app-images/leave_request.png'),
      },
    ],
  },
  {
    title: 'Utilities',
    image: require('@/assets/images/app-icons/checklist.png'),
    children: [
      {
        title: 'Overviews',
        route: 'provider-overviews',
        image: require('@/assets/images/app-icons/sub-menu/overview.png'),
      },
      {
        title: 'Photos',
        route: 'provider-photos',
        image: require('@/assets/images/app-icons/sub-menu/photos.png'),
      },
      {
        title: 'Reviews',
        route: 'provider-reviews',
        image: require('@/assets/images/app-icons/sub-menu/reviews.png'),
      },
      {
        title: 'Policies',
        route: 'provider-policies',
        image: require('@/assets/images/app-icons/sub-menu/policies.png'),
      },
    ],
  },
  {
    title: 'Settings',
    image: require('@/assets/images/app-icons/settings.png'),
    children: [
      {
        title: 'Account',
        hideInMenu: !window.SuperApp.walletEnabled,
        route: 'provider-wallet',
        image: require('@/assets/images/app-icons/sub-menu/account.png'),
      },
      {
        title: 'Profile',
        route: 'provider-profile',
        image: require('@/assets/images/app-icons/sub-menu/profile.png'),
      },
      {
        title: 'Payments Config',
        route: 'provider-payment-config',
        image: require('@/assets/images/app-icons/sub-menu/payment_config.png'),
      },
      {
        title: 'My Services',
        route: 'provider-my-services',
        image: require('@/assets/images/app-icons/sub-menu/my_services.png'),
      },
    ],
  },
]
