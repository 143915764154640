/* eslint-disable global-require */
/* eslint-disable eol-last */
export default [
  {
    title: 'Dashboard',
    route: 'shop-dashboard',
    image: require('@/assets/images/app-icons/Dashboard.png'),
  },
  {
    title: 'Analytics',
    route: 'shop-analytics',
    image: require('@/assets/images/app-icons/business-report.png'),
  },
  {
    title: 'Product',
    image: require('@/assets/images/app-icons/checklist.png'),
    children: [
      {
        title: 'Add Ons',
        route: 'shop-addons',
        image: require('@/assets/images/app-icons/sub-menu/add on.png'),
      },
      {
        title: 'Category',
        route: 'shop-category',
        image: require('@/assets/images/app-icons/sub-menu/Category.png'),
      },
      {
        title: 'Items',
        route: 'shop-items',
        image: require('@/assets/images/app-icons/sub-menu/Items.png'),
      },
      {
        title: 'Variations',
        route: 'shop-variations',
        image: require('@/assets/images/app-icons/sub-menu/variation.png'),
      },
    ],
  },
  {
    title: 'Offers',
    route: 'shop-promocodes',
    image: require('@/assets/images/app-icons/coupon.png'),
  },
  {
    title: 'Drivers/Agents',
    route: 'shop-agents',
    image: require('@/assets/images/app-icons/Drive.png'),
  },
  {
    title: 'Agents Dashboard',
    route: 'shop-agents-dashboard',
    image: require('@/assets/images/app-icons/agent dashboard.png'),
  },
  {
    title: 'Bookings',
    route: 'shop-bookings',
    image: require('@/assets/images/app-icons/calendar.png'),
    hideInMenu: false,
  },
  {
    title: 'My Documents',
    route: 'shop-documents',
    image: require('@/assets/images/app-images/My Documents.png'),
  },
  {
    title: 'Reviews',
    route: 'shop-reviews',
    image: require('@/assets/images/app-icons/reviews.png'),
  },
  {
    title: 'Messages',
    route: 'shop-messaging',
    image: require('@/assets/images/app-icons/chat.png'),
  },
  {
    title: 'Request History',
    route: 'order-history',
    image: require('@/assets/images/app-icons/exchange.png'),
  },
  {
    title: 'Wallet Transaction',
    route: 'shop-wallet',
    image: require('@/assets/images/app-icons/wallet_new.png'),
  },
  {
    title: 'Statement',
    image: require('@/assets/images/app-icons/history.png'),
    children: [
      {
        title: 'Order Statement',
        route: 'order-statement',
        image: require('@/assets/images/app-icons/sub-menu/order statement.png'),
      },
      {
        title: 'Shop Statement',
        route: 'shop-statement',
        image: require('@/assets/images/app-icons/sub-menu/shop statement.png'),
      },
    ],
  },
  {
    title: 'HRM',
    image: require('@/assets/images/app-images/icons8-hr-48.png'),
    children: [
      {
        title: 'Departments',
        route: 'shop-hrm-departments',
        image: require('@/assets/images/app-images/department.png'),
      },
      {
        title: 'Designation',
        route: 'shop-hrm-designation',
        image: require('@/assets/images/app-images/designation.png'),
      },
      {
        title: 'Employees',
        route: 'shop-hrm-employees',
        image: require('@/assets/images/app-images/employee.png'),
      },
      {
        title: 'Attendance',
        route: 'shop-hrm-attendance',
        image: require('@/assets/images/app-images/attendance.png'),
      },
      {
        title: 'Holidays',
        route: 'shop-hrm-holidays',
        image: require('@/assets/images/app-images/holidays.png'),
      },
      {
        title: 'Shift',
        route: 'shop-hrm-shift',
        image: require('@/assets/images/app-images/office_shift.png'),
      },
      {
        title: 'Leaves',
        route: 'shop-hrm-leaves',
        image: require('@/assets/images/app-images/leave_request.png'),
      },
      {
        title: 'Leave types',
        route: 'shop-hrm-leave-types',
        image: require('@/assets/images/app-images/leave_request.png'),
      },
    ],
  },
  {
    title: 'Utilities',
    image: require('@/assets/images/app-icons/checklist.png'),
    children: [
      {
        title: 'Overviews',
        route: 'shop-overviews',
        image: require('@/assets/images/app-icons/sub-menu/overview.png'),
      },
      {
        title: 'Photos',
        route: 'shop-photos',
        image: require('@/assets/images/app-icons/sub-menu/photos.png'),
      },
      {
        title: 'Reviews',
        route: 'shop-user-reviews',
        image: require('@/assets/images/app-icons/sub-menu/reviews.png'),
      },
      {
        title: 'Policies',
        route: 'shop-policies',
        image: require('@/assets/images/app-icons/sub-menu/policies.png'),
      },
    ],
  },
  {
    title: 'Settings',
    image: require('@/assets/images/app-icons/settings.png'),
    children: [
      {
        title: 'Profile',
        route: 'shop-profile',
        image: require('@/assets/images/app-icons/sub-menu/shop.png'),
      },
      {
        title: 'Delivery Settings',
        route: 'shop-delivery-settings',
        image: require('@/assets/images/app-icons/sub-menu/Delivery setting.png'),
      },
      {
        title: 'Payments Config',
        route: 'payment-config',
        image: require('@/assets/images/app-icons/sub-menu/payment_config.png'),
      },
    ],
  },
]
