var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"shop-nav-items"},[_c('span',{staticClass:"feather-icon-wrapper"},[_c('b-link',{attrs:{"to":{
        path: '/shop/dashboard',
      }}},[_c('span',{staticClass:"badge badge-up badge-pill badge-primary"},[_vm._v(_vm._s(_vm.notificationCountNewOrder))]),_c('img',{attrs:{"src":require('@/assets/images/app-icons/notification.png'),"width":"21"}})])],1),_c('span',{staticClass:"feather-icon-wrapper mobile-static-position"},[_c('b-nav-item-dropdown',{staticClass:"dropdown-cart mr-25 d-inline-block",attrs:{"menu-class":"dropdown-menu-media","right":""},scopedSlots:_vm._u([{key:"button-content",fn:function(){return [_c('span',{staticClass:"badge badge-up badge-pill badge-primary"},[_vm._v(_vm._s(_vm.chatNotifications.length))]),_c('img',{attrs:{"src":require('@/assets/images/app-icons/chat.png'),"width":"21"}})]},proxy:true}])},[_c('li',{staticClass:"dropdown-menu-header"},[_c('div',{staticClass:"dropdown-header d-flex pb-50 justify-content-between"},[_c('h4',{staticClass:"notification-title mb-0 mr-auto"},[_vm._v(" "+_vm._s(_vm.$t('Chats'))+" ")]),_c('b-link',{attrs:{"to":{
              path: '/shop/messaging',
            }}},[_c('b-badge',{attrs:{"pill":"","variant":"light-primary"}},[_vm._v(" "+_vm._s(_vm.$t('View All'))+" ")])],1)],1)]),(_vm.chatNotifications.length > 0)?_c('li',{staticClass:"scrollable-container media-list scroll-area ps-container scrollable-container media-list scroll-area ps ps--active-y auto-flow-scroll",attrs:{"tagname":"li"}},_vm._l((_vm.chatNotifications),function(item,index){return _c('div',{key:index,staticClass:"chat-nav-section-wrapper border-bottom p-1"},[_c('b-img',{staticClass:"chat-icon-dropdown",attrs:{"src":_vm.$helpers.getIcons('chat')},on:{"click":function($event){return _vm.setChatInfo(item)}}}),_c('p',{staticClass:"mb-0"},[_vm._v(_vm._s(_vm.$t('From:'))+" "+_vm._s(item.customer_name))]),_c('p',{staticClass:"mb-0"},[_vm._v(" "+_vm._s(_vm.$t('Order Number:'))+" "),_c('b-link',{attrs:{"to":{
                path: '/shop/order/agent/view/' + item.request_id,
              }}},[_vm._v(" "+_vm._s(item.order_number)+" ")])],1),_c('div',{staticClass:"d-flex justify-content-between"},[_c('p',{staticClass:"mb-0"},[_vm._v(_vm._s(_vm.$t('Payment Mode:'))+" "+_vm._s(item.payment_mode))]),_c('p',{staticClass:"mb-0"},[_vm._v(_vm._s(_vm.$t('Type:'))+" "+_vm._s(item.order_type))])])],1)}),0):_c('div',{staticClass:"p-2 text-center"},[_vm._v(_vm._s(_vm.$t('No Chats Available')))])])],1),_c('span',{staticClass:"feather-icon-wrapper mobile-static-position cursor-pointer"},[_c('b-nav-item-dropdown',{staticClass:"dropdown-cart mr-25 d-inline-block",attrs:{"menu-class":"dropdown-menu-media","right":""},scopedSlots:_vm._u([{key:"button-content",fn:function(){return [_c('span',{staticClass:"badge badge-up badge-pill badge-primary"},[_vm._v(_vm._s(_vm.storeNotifications.total_count))]),_c('img',{attrs:{"src":require('@/assets/images/app-icons/New Order.png'),"width":"21"}})]},proxy:true}])},[_c('li',{staticClass:"dropdown-menu-header"},[_c('div',{staticClass:"dropdown-header d-flex pb-50 justify-content-between"},[_c('h4',{staticClass:"notification-title mb-0 mr-auto"},[_vm._v(" "+_vm._s(_vm.$t('Notification'))+" ")]),_c('b-link',{attrs:{"to":{
              path: '/shop/notifications',
            }}},[_c('b-badge',{attrs:{"pill":"","variant":"light-primary"}},[_vm._v(" "+_vm._s(_vm.$t('View All'))+" ")])],1)],1)]),(_vm.storeNotificationsInfo.length > 0)?_c('li',{staticClass:"scrollable-container media-list scroll-area ps-container scrollable-container media-list scroll-area ps ps--active-y auto-flow-scroll",attrs:{"tagname":"li"}},_vm._l((_vm.storeNotificationsInfo),function(item,index){return _c('div',{key:index,staticClass:"chat-nav-section-wrapper border-bottom p-1"},[_c('div',{staticClass:"d-flex justify-content-between"},[_c('p',{staticClass:"mb-0"},[_vm._v(_vm._s(_vm.$t('Title:'))+" "+_vm._s(item.title))]),_c('p',{staticClass:"mb-0"},[_vm._v(_vm._s(_vm.$t('Time:'))+" "+_vm._s(item.time_at))])]),_c('div',{staticClass:"d-flex justify-content-between"},[_c('p',{staticClass:"mb-0"},[_vm._v(_vm._s(_vm.$t('Service Type:'))+" "+_vm._s(item.service))]),_c('p',{staticClass:"mb-0"},[_vm._v(_vm._s(_vm.$t('Status:'))+" "+_vm._s(item.status))])]),_c('div',{staticClass:"d-flex"},[_c('p',{staticClass:"mb-0"},[_vm._v(_vm._s(_vm.$t('Description:'))+" "+_vm._s(item.descriptions))])])])}),0):_c('div',{staticClass:"p-2 text-center"},[_vm._v(_vm._s(_vm.$t('No Notification Available')))])])],1),(!_vm.isMobileView)?_c('span',{staticClass:"feather-icon-wrapper"},[_c('b-link',{attrs:{"to":{
        path: '/shop/payment-config',
      }}},[_c('span',{staticClass:"badge badge-up badge-pill badge-primary"},[_vm._v(_vm._s(_vm.bankCount))]),_c('img',{attrs:{"src":require('@/assets/images/app-icons/settings_shop.png'),"width":"21"}})])],1):_vm._e(),_c('b-modal',{attrs:{"id":"chat-modal-popup-navbar","no-close-on-backdrop":true,"hide-footer":""},on:{"hidden":function($event){_vm.chatWrapper.showChatMessage = false}},model:{value:(_vm.chatWrapper.showChatMessage),callback:function ($$v) {_vm.$set(_vm.chatWrapper, "showChatMessage", $$v)},expression:"chatWrapper.showChatMessage"}},[_c('chat',{attrs:{"chat-type":'ORDER',"data-id":_vm.chatWrapper.orderIdInfo,"user-name":_vm.chatWrapper.userChatName,"customer-id":_vm.chatWrapper.userCustomerId,"store-provider-id":_vm.chatWrapper.storeProviderId,"provider-name":_vm.chatWrapper.storeChatName}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }