<template>
  <div class="provider-nav-items">
    <span class="feather-icon-wrapper">
      <b-link
        :to="{
          path: '/provider/requests',
        }"
      >
        <span class="badge badge-up badge-pill badge-primary">{{ notificationCountNewOrder }}</span>
        <img
          :src="require('@/assets/images/app-icons/notification.png')"
          width="21"
        >
      </b-link>
    </span>
    <span class="feather-icon-wrapper">
      <span class="badge badge-up badge-pill badge-primary">0</span>
      <img
        :src="require('@/assets/images/app-icons/chat.png')"
        width="21"
      >
    </span>
    <span class="feather-icon-wrapper cursor-pointer">
      <b-link>
        <span class="badge badge-up badge-pill badge-primary">{{ providerNotifications.count ? providerNotifications.count : 0 }}</span>
        <img
          :src="require('@/assets/images/app-icons/New Order.png')"
          width="21"
        >
      </b-link>
    </span>
    <span
      v-if="!isMobileView"
      class="feather-icon-wrapper"
    >
      <b-link
        :to="{
          path: '/provider/payment-config',
        }"
      >
        <span class="badge badge-up badge-pill badge-primary">{{ bankCount }}</span>
        <img
          :src="require('@/assets/images/app-icons/settings_shop.png')"
          width="21"
        >
      </b-link>
    </span>
  </div>
</template>

<script>
import { BLink } from 'bootstrap-vue'
import { showErrorNotification } from '@/@core/comp-functions/ui/app'
import eventBus from '@/libs/event-bus'
import { useProviderUi } from '../useProvider'

export default {
  components: {
    BLink,
  },
  data() {
    return {
      providerNotifications: {
        count: 0,
        total_count: 0,
      },
      notificationCountNewOrder: 0,
    }
  },
  computed: {
    bankCount() {
      if (!window.SuperApp.getters.userInfo()) return 0
      return window.SuperApp.getters.userInfo().is_bankdetail === 0 ? 1 : 0
    },
    isMobileView() {
      return this.$store.getters['app/windowSize'] <= 500
    },
  },
  mounted() {
    this.getNotifications()
    eventBus.$on('set-new-order-provider-notification', this.increaseNotification)
    eventBus.$on('reset-order-provider-notification', this.resetNotification)
  },
  destroyed() {
    eventBus.$off('set-new-order-provider-notification', this.increaseNotification)
    eventBus.$off('reset-order-provider-notification', this.resetNotification)
  },
  methods: {
    resetNotification() {
      this.notificationCountNewOrder = 0
    },
    increaseNotification() {
      this.notificationCountNewOrder += 1
    },
    getNotifications() {
      const { getNotificationCount } = useProviderUi()

      getNotificationCount()
        .then(({ data }) => {
          if (data.statusCode === '200') {
            this.providerNotifications = data.responseData
          }
        })
        .catch(error => {
          showErrorNotification(this, error)
        })
    },
  },
}
</script>

<style scoped>
.provider-nav-items .feather-icon-wrapper {
  margin: 0px 10px;
  position: relative;
}

@media only screen and (max-width: 767px) {
  /* .provider-nav-items .feather-icon-wrapper {
    margin: 0px 2px;
  } */

  .provider-nav-items .feather-icon-wrapper li {
    float: unset;
  }
}
</style>
