/* eslint-disable global-require */
/* eslint-disable eol-last */
export default [
  {
    title: 'Dashboard',
    route: 'user-dashboard',
    image: require('@/assets/images/app-icons/ai/dashboard-48.png'),
  },
  {
    title: 'Marketplace',
    image: require('@/assets/images/app-icons/marketplace.png'),
    children: [
      {
        title: 'Explore',
        route: 'user-home',
        image: require('@/assets/images/app-icons/1shop.png'),
      },
      {
        title: 'My History',
        image: require('@/assets/images/app-icons/1history.png'),
        children: [
          {
            title: 'Transport',
            route: 'user-transport',
            image: require('@/assets/images/app-icons/sub-menu/transport.png'),
          },
          {
            title: 'Order',
            route: 'user-order',
            image: require('@/assets/images/app-icons/sub-menu/order.png'),
          },
          {
            title: 'Services',
            route: 'user-services',
            image: require('@/assets/images/app-icons/sub-menu/Service.png'),
          },
          {
            title: 'Delivery',
            route: 'user-delivery',
            image: require('@/assets/images/app-icons/sub-menu/delivery.png'),
          },
        ],
      },
    ]
  },
  // {
  //   title: 'Profile',
  //   route: 'user-profile',
  //   image: require('@/assets/images/app-icons/user/Profile.png'),
  // },
  // {
  //   title: 'Wallet',
  //   route: 'user-wallet',
  //   hideInMenu: !window.SuperApp.walletEnabled,
  //   image: require('@/assets/images/app-icons/user/wallet.png'),
  // },
  // {
  //   title: 'Messages',
  //   route: 'user-messaging',
  //   image: require('@/assets/images/app-icons/live_chat.jpeg'),
  // },
  {
    title: 'AI Tools',
    image: require('@/assets/images/app-icons/ai/ai-48.png'),
    children: [
      {
        title: 'AI Chat',
        route: 'user-ai-chat',
        image: require('@/assets/images/app-icons/ai/chat-48.png'),
      },
      {
        title: 'AI Images',
        route: 'user-ai-images',
        image: require('@/assets/images/app-icons/ai/image-48.png'),
      },
      {
        title: 'AI Code',
        route: 'user-ai-code',
        image: require('@/assets/images/app-icons/ai/code-48.png'),
      },
      {
        title: 'AI Text to Speech',
        route: 'user-ai-t2s',
        image: require('@/assets/images/app-icons/ai/speech-48.png'),
      },
      {
        title: 'AI Speech To Text',
        route: 'user-ai-s2t',
        image: require('@/assets/images/app-icons/ai/voice-48.png'),
      },
      {
        title: 'AI Writer',
        route: 'user-ai-writer',
        image: require('@/assets/images/app-icons/ai/template-48.png'),
      },
    ]
  }
]
