/* eslint-disable global-require */
export default [
  {
    title: 'Dashboard',
    route: 'provider-agent-dashboard',
    image: require('@/assets/images/app-icons/Dashboard.png'),
  },
  {
    title: 'Requests',
    route: 'provider-agent-requests',
    image: require('@/assets/images/app-icons/exchange.png'),
  },
  {
    title: 'Settings',
    image: require('@/assets/images/app-icons/settings.png'),
    children: [
      {
        title: 'Profile',
        route: 'provider-profile',
        image: require('@/assets/images/app-icons/sub-menu/profile.png'),
      },
    ],
  },
]
