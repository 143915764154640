<template>
  <div class="agent-nav-items">
    <incoming-request />
  </div>
</template>

<script>
import IncomingRequest from './IncomingRequest.vue'

export default {
  components: {
    IncomingRequest,
  },
}
</script>
