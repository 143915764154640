/* eslint-disable global-require */
/* eslint-disable eol-last */
export default [
  {
    title: 'CMS Pages',
    image: require('@/assets/images/app-icons/document.png'),
    children: [
      {
        title: 'FAQ',
        route: 'admin-cmspages-faq',
      },
      {
        title: 'FAQ Category',
        route: 'admin-cmspages-faq-category',
      },
      {
        title: 'Blogs',
        route: 'admin-cmspages-blog',
      },
      {
        title: 'Knowledge Base',
        route: 'admin-cmspages-knowledge-base',
      },
      {
        title: 'Knowledge Base Category',
        route: 'admin-cmspages-knowledge-base-category',
      },
      {
        title: 'Knowledge Base Questions',
        route: 'admin-cmspages-knowledge-base-questions',
      },
      {
        title: 'Others',
        route: 'admin-cmspages-other-pages',
      },
    ],
  },
  {
    title: 'Utilities',
    image: require('@/assets/images/app-icons/checklist.png'),
    children: [
      {
        title: 'Variations',
        route: 'admin-utilities-variations',
      },
    ],
  },
  {
    title: 'Settings',
    image: require('@/assets/images/app-icons/settings.png'),
    children: [
      {
        title: 'Languages',
        route: 'admin-language-translations',
      },
    ],
  },
  {
    title: 'Subscription Package',
    image: require('@/assets/images/app-icons/settings.png'),
    children: [
      {
        title: 'Store Subscription Package',
        route: {
          path: '/admin/subscription-packages/store'
        }
      },
      {
        title: 'Provider Subscription Package',
        route: {
          path: '/admin/subscription-packages/provider'
        }
      },
      {
        title: 'User Subscription Package',
        route: {
          path: '/admin/subscription-packages/user'
        }
      },
      {
        title: 'Coupons',
        route: {
          path: '/admin/coupons'
        }
      },
      {
        title: 'Subscribers',
        route: {
          path: '/admin/subscribers'
        }
      },
    ],
  },
  {
    title: 'Import Stores/Providers',
    image: require('@/assets/images/app-icons/checklist.png'),
    children: [
      {
        title: 'Import Stores/Providers',
        route: 'admin-import-users',
      },
    ],
  },
]
