<template>
  <div class="shop-nav-items">
    <span
      class="feather-icon-wrapper"
    >
      <b-link
        :to="{
          path: '/shop/dashboard',
        }"
      >
        <span class="badge badge-up badge-pill badge-primary">{{ notificationCountNewOrder }}</span>
        <img
          :src="require('@/assets/images/app-icons/notification.png')"
          width="21"
        >
      </b-link>
    </span>
    <span class="feather-icon-wrapper mobile-static-position">
      <b-nav-item-dropdown
        class="dropdown-cart mr-25 d-inline-block"
        menu-class="dropdown-menu-media"
        right
      >
        <template #button-content>
          <span class="badge badge-up badge-pill badge-primary">{{ chatNotifications.length }}</span>
          <img
            :src="require('@/assets/images/app-icons/chat.png')"
            width="21"
          >
        </template>

        <!-- Header -->
        <li class="dropdown-menu-header">
          <div class="dropdown-header d-flex pb-50 justify-content-between">
            <h4 class="notification-title mb-0 mr-auto">
              {{ $t('Chats') }}
            </h4>
            <b-link
              :to="{
                path: '/shop/messaging',
              }"
            >
              <b-badge
                pill
                variant="light-primary"
              >
                {{ $t('View All') }}
              </b-badge>
            </b-link>
          </div>
        </li>

        <!-- Cart Items Service -->
        <li
          v-if="chatNotifications.length > 0"
          class="scrollable-container media-list scroll-area ps-container scrollable-container media-list scroll-area ps ps--active-y auto-flow-scroll"
          tagname="li"
        >
          <div
            v-for="(item, index) of chatNotifications"
            :key="index"
            class="chat-nav-section-wrapper border-bottom p-1"
          >
            <b-img
              class="chat-icon-dropdown"
              :src="$helpers.getIcons('chat')"
              @click="setChatInfo(item)"
            />
            <p class="mb-0">{{ $t('From:') }} {{ item.customer_name }}</p>
            <p class="mb-0">
              {{ $t('Order Number:') }}
              <b-link
                :to="{
                  path: '/shop/order/agent/view/' + item.request_id,
                }"
              >
                {{ item.order_number }}
              </b-link>
            </p>
            <div class="d-flex justify-content-between">
              <p class="mb-0">{{ $t('Payment Mode:') }} {{ item.payment_mode }}</p>
              <p class="mb-0">{{ $t('Type:') }} {{ item.order_type }}</p>
            </div>
          </div>
        </li>
        <div
          v-else
          class="p-2 text-center"
        >{{ $t('No Chats Available') }}</div>
      </b-nav-item-dropdown>
    </span>
    <span class="feather-icon-wrapper mobile-static-position cursor-pointer">
      <b-nav-item-dropdown
        class="dropdown-cart mr-25 d-inline-block"
        menu-class="dropdown-menu-media"
        right
      >
        <template #button-content>
          <span class="badge badge-up badge-pill badge-primary">{{ storeNotifications.total_count }}</span>
          <img
            :src="require('@/assets/images/app-icons/New Order.png')"
            width="21"
          >
        </template>

        <!-- Header -->
        <li class="dropdown-menu-header">
          <div class="dropdown-header d-flex pb-50 justify-content-between">
            <h4 class="notification-title mb-0 mr-auto">
              {{ $t('Notification') }}
            </h4>
            <b-link
              :to="{
                path: '/shop/notifications',
              }"
            >
              <b-badge
                pill
                variant="light-primary"
              >
                {{ $t('View All') }}
              </b-badge>
            </b-link>
          </div>
        </li>

        <!-- Cart Items Service -->
        <li
          v-if="storeNotificationsInfo.length > 0"
          class="scrollable-container media-list scroll-area ps-container scrollable-container media-list scroll-area ps ps--active-y auto-flow-scroll"
          tagname="li"
        >
          <div
            v-for="(item, index) of storeNotificationsInfo"
            :key="index"
            class="chat-nav-section-wrapper border-bottom p-1"
          >
            <div class="d-flex justify-content-between">
              <p class="mb-0">{{ $t('Title:') }} {{ item.title }}</p>
              <p class="mb-0">{{ $t('Time:') }} {{ item.time_at }}</p>
            </div>
            <div class="d-flex justify-content-between">
              <p class="mb-0">{{ $t('Service Type:') }} {{ item.service }}</p>
              <p class="mb-0">{{ $t('Status:') }} {{ item.status }}</p>
            </div>
            <div class="d-flex">
              <p class="mb-0">{{ $t('Description:') }} {{ item.descriptions }}</p>
            </div>
          </div>
        </li>
        <div
          v-else
          class="p-2 text-center"
        >{{ $t('No Notification Available') }}</div>
      </b-nav-item-dropdown>
    </span>
    <span
      v-if="!isMobileView"
      class="feather-icon-wrapper"
    >
      <b-link
        :to="{
          path: '/shop/payment-config',
        }"
      >
        <span class="badge badge-up badge-pill badge-primary">{{ bankCount }}</span>
        <img
          :src="require('@/assets/images/app-icons/settings_shop.png')"
          width="21"
        >
      </b-link>
    </span>
    <b-modal
      id="chat-modal-popup-navbar"
      v-model="chatWrapper.showChatMessage"
      :no-close-on-backdrop="true"
      hide-footer
      @hidden="chatWrapper.showChatMessage = false"
    >
      <chat
        :chat-type="'ORDER'"
        :data-id="chatWrapper.orderIdInfo"
        :user-name="chatWrapper.userChatName"
        :customer-id="chatWrapper.userCustomerId"
        :store-provider-id="chatWrapper.storeProviderId"
        :provider-name="chatWrapper.storeChatName"
      />
    </b-modal>
  </div>
</template>

<script>
import { BLink, BNavItemDropdown, BBadge, BImg, BModal } from 'bootstrap-vue'
import { showErrorNotification } from '@/@core/comp-functions/ui/app'
import eventBus from '@/libs/event-bus'
import { useStoreUi } from '../useStore'
import Chat from '../../shared/chat/Chat.vue'

export default {
  components: {
    BLink,
    BImg,
    Chat,
    BModal,
    BBadge,
    BNavItemDropdown,
  },
  data() {
    return {
      chatWrapper: {
        showChatMessage: false,
        orderIdInfo: '',
        userChatName: '',
        userCustomerId: '',
        storeProviderId: '',
        storeChatName: '',
      },
      storeNotifications: {
        total: 0,
        total_count: 0,
      },
      perfectScrollbarSettings: {
        maxScrollbarLength: 60,
        wheelPropagation: false,
      },
      notificationCountNewOrder: 0,
      chatNotifications: [],
      storeNotificationsInfo: [],
    }
  },
  computed: {
    bankCount() {
      if (!window.SuperApp.getters.userInfo()) return 0
      return window.SuperApp.getters.userInfo().is_bankdetail === 0 ? 1 : 0
    },
    isMobileView() {
      return this.$store.getters['app/windowSize'] <= 500
    },
  },
  mounted() {
    this.getNotifications()
    this.getChatNotifications()
    eventBus.$on('set-new-order-store-notification', this.increaseNotification)
    eventBus.$on('reset-order-store-notification', this.resetNotification)
  },
  destroyed() {
    eventBus.$off('set-new-order-store-notification', this.increaseNotification)
    eventBus.$off('reset-order-store-notification', this.resetNotification)
  },
  methods: {
    resetNotification() {
      this.notificationCountNewOrder = 0
    },
    setChatInfo(item) {
      this.chatWrapper.orderIdInfo = item.request_id
      this.chatWrapper.userChatName = item.customer_name
      this.chatWrapper.userCustomerId = item.user_id
      this.chatWrapper.storeProviderId = item.store_id
      const shop = window.SuperApp.getters.userInfo()
      this.chatWrapper.storeChatName = `${shop.first_name} ${shop.last_name}`
      this.chatWrapper.showChatMessage = true
    },
    increaseNotification() {
      this.notificationCountNewOrder += 1
    },
    shopNotifications() {
      const { shopNotifications } = useStoreUi()

      shopNotifications(`?limit=${this.storeNotifications.total_count > 10 ? this.storeNotifications.total_count : 10}`)
        .then(({ data }) => {
          if (data.statusCode === '200') {
            this.storeNotificationsInfo = data.responseData.notification.data
          }
        })
        .catch(error => {
          showErrorNotification(this, error)
        })
    },
    getNotifications() {
      const { shopNotificationCount } = useStoreUi()

      shopNotificationCount()
        .then(({ data }) => {
          if (data.statusCode === '200') {
            this.storeNotifications = data.responseData
            this.shopNotifications()
          }
        })
        .catch(error => {
          showErrorNotification(this, error)
        })
    },
    getChatNotifications() {
      const { getChatNotifications } = useStoreUi()

      getChatNotifications()
        .then(({ data }) => {
          if (data.statusCode === '200') {
            this.chatNotifications = data.responseData
          }
        })
        .catch(error => {
          showErrorNotification(this, error)
        })
    },
  },
}
</script>

<style scoped>
.shop-nav-items .feather-icon-wrapper {
  margin: 0px 10px;
  position: relative;
}

.shop-nav-items img.chat-icon-dropdown {
  position: absolute;
  width: 25px;
  right: 15px;
  cursor: pointer;
}

@media only screen and (max-width: 767px) {
  .shop-nav-items .feather-icon-wrapper {
    margin: 0px 2px;
  }

  .shop-nav-items .feather-icon-wrapper li {
    float: unset;
  }
}
</style>
